<script>
import Swal from "sweetalert2";
export default {

  data() {
    return {
      tableData: [],
      title: "Types de pénalités",
      types: [],
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Types de pénalités",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.types.length;
    },
  },
  mounted() {
    this.totalRows = this.types.length;
    this.refreshTypesTable();
  },
  methods: {
    
    deleteType(typeName, roleUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type " + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/base/contratType/delete/" + roleUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  _this.refreshTypesTable();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    refreshTypesTable() {
      this.$http
        .post("/base/contratType/list")
        .then((res) => {
          this.types = res.data.original.list;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

};
</script>

<template>
  <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <a @click="refreshTypesTable()" href="javascript:;"
              ><h1><i class="bx bx-rotate-right" style="float: right"></i></h1
            ></a>
            <router-link  :to="{ name: 'settings.contrat_type.new' }"
              ><b-button variant="primary"
                >Ajouter</b-button
              ></router-link
            >
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Recherche..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <div class="table-responsive mb-0">
                <table class="table table-centered table-nowrap">
                  <thead class="thead-light">
                    <tr>
                      <th>Désignation</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in types" :key="data.id">
                      <td>
                        <router-link
                          :to="{
                            name: 'settings.roles.permissions',
                            params: { uid: data.uuid },
                          }"
                          >{{ data.name }}</router-link
                        >
                      </td>
                      <td>{{ data.description }}</td>
                      <td>
                       
                        <router-link v-show="data.can_delete"
                          :to="{
                            name: 'settings.contrat_type.edit',
                            params: { uid: data.uuid },
                          }"
                          class="mr-3 text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Modifier"
                          data-original-title="Edit"
                          ><i class="mdi mdi-pencil font-size-18"></i
                        ></router-link>

                        <a v-show="data.can_delete"
                          @click="deleteType(data.name, data.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>